@font-face {
  font-family: "Conv_RalewayDots-Regular";
  src: url("fonts/RalewayDots-Regular.eot");
  src: local("☺"), url("fonts/RalewayDots-Regular.woff") format("woff"),
    url("fonts/RalewayDots-Regular.ttf") format("truetype"),
    url("fonts/RalewayDots-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "KGPrimaryDots-Regular";
  src: url("fonts/RalewayDots-Regular.eot");
  src: local("☺"), url("fonts/RalewayDots-Regular.woff") format("woff"),
    url("fonts/RalewayDots-Regular.ttf") format("truetype"),
    url("fonts/RalewayDots-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AbcCursiveDottedLined";
  src: url("fonts/AbcCursiveDottedLined.eot");
  src: url("fonts/AbcCursiveDottedLined.eot?#iefix") format("embedded-opentype"),
    url("fonts/AbcCursiveDottedLined.woff2") format("woff2"),
    url("fonts/AbcCursiveDottedLined.ttf") format("truetype");
}
